import { Injectable } from '@angular/core';
import { GenericRequestService } from './generic-request.service';
import { ProcesswebService } from './processweb.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductCreationService extends GenericRequestService {

  constructor(protected processWebService: ProcesswebService, protected router: Router) {
    super(processWebService, router);
  }

  getAllProducts(body: any) {
    return this.productRequest(body);
  }

  getProductType(body: any) {
    return new Promise((resp, reject) => {
      console.log("getProductType");
      console.log(body);
      const request: string = JSON.stringify(body);
      this.processWebService.execProcessEnd(request, "/in-table", "post")
        .subscribe((res: any) => {
          console.log("getProductType....");
          console.log(res);
          if (res.idresponse == 1) {
            resp(res);
          } else {
            reject(res);
          }
        });
    }
    );
  }

  getLastConfigurationProduct(body: any) {
    return this.productRequest(body);
  }

  saveProductInformation(body: any) {
    return this.productRequest(body);
  }

  protected productRequest(body: any, operation?: string) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken()
      //   .subscribe((token: any) => {
      console.log(body);
      const request: string = JSON.stringify(body);
      this.processWebService.execProcessEnd(request, "/commission-administration-product-commission", "post")
        .subscribe((res: any) => {
          // tslint:disable-next-line:triple-equals
          console.log(res);
          if (res.idresponse == 1) {
            resp(res);
          } else {
            reject(res);
          }
        });
    }
    );
    // });
  }
}
